export const inputForm = [
  { label: 'Nombre y Apellido del cliente:', name: 'name', config: { required: true } },
  { label: 'Nº de documento (DNI/CIF) del cliente:', name: 'dni', config: { required: true } },
  {
    label: 'Teléfono:',
    name: 'phone',
    config: {
      required: true,
      pattern: '^\\+?\\d{7,15}$', // numbers with optional "+" and 7-15 digits
      title: 'Debe ser un número válido con entre 7 y 15 dígitos.' // Tooltip for invalid input
    }
  },
  { label: 'Extensión/Tlf EdA:', name: 'edaNumber', autocomplete: true, config: { required: true } },
  { label: 'Motivo de la llamada:', name: 'callReason', config: { maxLength: 30, required: true } }
]
