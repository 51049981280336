import React from 'react'
import PropTypes from 'prop-types'
import * as style from '../pages/index.module.css'
import Button from './Button'
import ErrorToast from './ErrorToast'
import InputText from './InputText'
import { removeLogin } from '../utils/login'
import { inputForm } from '../utils/inputForm'

const ContactForm = ({ onSubmit, submitLabel }) => {
  const agentId = typeof window !== 'undefined' ? localStorage.getItem('agentId') : null

  return (
    <section>
      <article className={style.container}>
        <header>
          <h2>Transferencia de llamada (Login: {agentId})</h2>
          <Button onClick={removeLogin} >Editar Login</Button>
        </header>
        <ErrorToast/>
        <form
          className={style.formContainer}
          id="callForm"
          onSubmit={onSubmit}
          autoComplete="off"
        >
          {inputForm.map(({ config = {}, label, name, autocomplete = false }) => (
            <InputText
              key={name}
              inputProps={config}
              label={label}
              name={name}
              autocomplete={autocomplete}
            />
          ))}
        </form>
        <footer>
          <Button innerProps={{ form: 'callForm' }} type="submit" route="/contact-form/">
            {submitLabel}
          </Button>
        </footer>
      </article>
    </section>
  )
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string
}

ContactForm.defaultProps = {
  onSubmit: () => {},
  submitLabel: 'Enviar'
}

export default ContactForm
